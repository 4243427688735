const constants = {
    UPDATE_AUTH_FLAG: "UPDATE_AUTH_FLAG",
    UPDATE_USER_INFO: "UPDATE_USER_INFO",
    UPDATE_CONFIG_INFO: "UPDATE_CONFIG_INFO",
    UPDATE_CONFIGPARAMS: "UPDATE_CONFIGPARAMS",
    USER_DETAILS_FETCHING: "USER_DETAILS_FETCHING",
    CANCEL_USER_DETAILS_FETCHING: "CANCEL_USER_DETAILS_FETCHING",
    UPDATE_PROJECT_ID: 'UPDATE_PROJECT_ID',
    SET_CONFIGURATION_API: "SET_CONFIGURATION_API",
    UNSET_CONFIGURATION_API: "UNSET_CONFIGURATION_API",
    SET_CHARTS_OVERVIEW_API: "SET_CHARTS_OVERVIEW_API",
    UNSET_CHARTS_OVERVIEW_API: "UNSET_CHARTS_OVERVIEW_API",
    SET_WOEKFILE_CHARTS_OVERVIEW_API: "SET_WOEKFILE_CHARTS_OVERVIEW_API",
    UNSET_WORKFILE_CHARTS_OVERVIEW_API: "UNSET_WORKFILE_CHARTS_OVERVIEW_API",
    SET_FILTERS_API: "SET_FILTERS_API",
    UNSET_FILTERS_API: "UNSET_FILTERS_API",
    SET_WORKFILE_FILTERS_API: "SET_WORKFILE_FILTERS_API",
    UNSET_WORKFILE_FILTERS_API: "UNSET_WORKFILE_FILTERS_API",
    SET_PATENTS_API: "SET_PATENTS_API",
    UNSET_PATENTS_API: "UNSET_PATENTS_API",
    SET_WORKFILE_PATENTS_API: "SET_WORKFILE_PATENTS_API",
    UNSET_WORKFILE_PATENTS_API: "UNSET_WORKFILE_PATENTS_API",
    SET_STARTUP_API: "SET_STARTUP_API",
    UNSET_STARTUP_API: "UNSET_STARTUP_API",
    SET_WORKFILE_STARTUP_API: "SET_WORKFILE_STARTUP_API",
    UNSET_WORKFILE_STARTUP_API: "UNSET_WORKFILE_STARTUP_API",
    SET_PATENTS_PAGE_API: "SET_PATENTS_PAGE_API",
    UNSET_PATENTS_PAGE_API: "UNSET_PATENTS_PAGE_API",
    SET_WORKFILE_PATENTS_PAGE_API: "SET_WORKFILE_PATENTS_PAGE_API",
    UNSET_WORKFILE_PATENTS_PAGE_API: "UNSET_WORKFILE_PATENTS_PAGE_API",
    SET_CC_TAXONOMY_API: "SET_CC_TAXONOMY_API",
    UNSET_CC_TAXONOMY_API: "UNSET_CC_TAXONOMY_API",
    SET_WORKFILE_CC_TAXONOMY_API: "SET_WORKFILE_CC_TAXONOMY_API",
    UNSET_WORKFILE_CC_TAXONOMY_API: "UNSET_WORKFILE_CC_TAXONOMY_API",
    SET_CPC_TAXONOMY_API: "SET_CPC_TAXONOMY_API",
    UNSET_CPC_TAXONOMY_API: "UNSET_CPC_TAXONOMY_API",

    SET_CORPORATE_TREE_API: "SET_CORPORATE_TREE_API",
    UNSET_CORPORATE_TREE_API: "UNSET_CORPORATE_TREE_API",
    SET_CPC_TREE_API: "SET_CPC_TREE_API",
    UNSET_CPC_TREE_API: "UNSET_CPC_TREE_API",

    SET_CORPORATE_TREE_STATUS: "SET_CORPORATE_TREE_STATUS",
    UNSET_CORPORATE_TREE_STATUS: "UNSET_CORPORATE_TREE_STATUS",
    SET_CPC_TREE_STATUS: "SET_CPC_TREE_STATUS",
    UNSET_CPC_TREE_STATUS: "UNSET_CPC_TREE_STATUS",

    SET_SCROLL_TOP_FLAG: "SET_SCROLL_TOP_FLAG",
    UNSET_SCROLL_TOP_FLAG: "UNSET_SCROLL_TOP_FLAG",
    SET_WORKFILE_SCROLL_TOP_FLAG: "SET_WORKFILE_SCROLL_TOP_FLAG",
    UNSET_WORKFILE_SCROLL_TOP_FLAG: "UNSET_WORKFILE_SCROLL_TOP_FLAG",

    UPDATE_ANALYTICS_PANEL_DATA: "UPDATE_ANALYTICS_PANEL_DATA",
    CLEAR_ANALYTICS_PANEL_DATA: "CLEAR_ANALYTICS_PANEL_DATA",

    UPDATE_PATENTS_PANEL_DATA: "UPDATE_PATENTS_PANEL_DATA",
    CLEAR_PATENTS_PANEL_DATA: "CLEAR_PATENTS_PANEL_DATA",

    UPDATE_CHARTS_OVERVIEW_DATA: "UPDATE_CHARTS_OVERVIEW_DATA",
    UPDATE_WORKFILE_CHARTS_OVERVIEW_DATA: "UPDATE_WORKFILE_CHARTS_OVERVIEW_DATA",

    UPDATE_CHARTS_FULL_DATA: "UPDATE_CHARTS_FULL_DATA",
    UPDATE_COMPANY_CHARTS_FULL_DATA:"UPDATE_COMPANY_CHARTS_FULL_DATA",
    UPDATE_WORKFILE_CHARTS_FULL_DATA: "UPDATE_WORKFILE_CHARTS_FULL_DATA",

    SET_CHART_FULL_SCREEN: "SET_CHART_FULL_SCREEN",
    UNSET_CHART_FULL_SCREEN: "UNSET_CHART_FULL_SCREEN",
    SET_WORKFILE_CHART_FULL_SCREEN: "SET_WORKFILE_CHART_FULL_SCREEN",
    UNSET_WORKFILE_CHART_FULL_SCREEN: "UNSET_WORKFILE_CHART_FULL_SCREEN",
    
    SET_ANALYTICS_FULL_DATA_UPTO_DATE: "SET_ANALYTICS_FULL_DATA_UPTO_DATE",
    UNSET_ANALYTICS_FULL_DATA_UPTO_DATE: "UNSET_ANALYTICS_FULL_DATA_UPTO_DATE",
    SET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE: "SET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE",
    UNSET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE: "UNSET_WORKFILE_ANALYTICS_FULL_DATA_UPTO_DATE",

    SET_FRESH_TAXONOMY_CC: "SET_FRESH_TAXONOMY_CC",
    UNSET_FRESH_TAXONOMY_CC: "UNSET_FRESH_TAXONOMY_CC",
    SET_WORKFILE_FRESH_TAXONOMY_CC: "SET_WORKFILE_FRESH_TAXONOMY_CC",
    UNSET_WORKFILE_FRESH_TAXONOMY_CC: "UNSET_WORKFILE_FRESH_TAXONOMY_CC",
    TAXONOMY_CC: "TAXONOMY_CC",
    WORKFILE_TAXONOMY_CC: "WORKFILE_TAXONOMY_CC",

    SET_FRESH_TAXONOMY_CPC: "SET_FRESH_TAXONOMY_CPC",
    UNSET_FRESH_TAXONOMY_CPC: "UNSET_FRESH_TAXONOMY_CPC",
    TAXONOMY_CPC: "TAXONOMY_CPC",

    UPDATE_APPLIED_TAXONOMY: "UPDATE_APPLIED_TAXONOMY",
    UPDATE_WORKFILE_APPLIED_TAXONOMY: "UPDATE_WORKFILE_APPLIED_TAXONOMY",
    UPDATE_DUMMY_APPLIED_TAXONOMY: "UPDATE_DUMMY_APPLIED_TAXONOMY",
    UPDATE_WORKFILE_DUMMY_APPLIED_TAXONOMY: "UPDATE_WORKFILE_DUMMY_APPLIED_TAXONOMY",
    UPDATE_SELECTED_TAXONOMY: "UPDATE_SELECTED_TAXONOMY",

    UPDATE_SELECTED_FILTERS_TYPE: "UPDATE_SELECTED_FILTERS_TYPE",

    ENABLE_FIELD_SEARCH: "ENABLE_FIELD_SEARCH",
    CLOSE_FIELD_SEARCH: "CLOSE_FIELD_SEARCH",
    ENABLE_WORKFILE_FIELD_SEARCH: "ENABLE_WORKFILE_FIELD_SEARCH",
    CLOSE_WORKFILE_FIELD_SEARCH: "CLOSE_WORKFILE_FIELD_SEARCH",
    
    BASIC_QUERY: "BASIC_QUERY",
    WORKFILE_BASIC_QUERY: "WORKFILE_BASIC_QUERY",
    FORMATTED_QUERY: "FORMATTED_QUERY",
    WORKFILE_FORMATTED_QUERY: "WORKFILE_FORMATTED_QUERY",
    FIELD_QUERY: "FIELD_QUERY",
    FIELD_QUERY_DATA: "FIELD_QUERY_DATA",
    WORKFILE_FIELD_QUERY: "WORKFILE_FIELD_QUERY",
    UPDATE_QUERY_METADATA: "UPDATE_QUERY_METADATA",
    UPDATE_WORKFILE_QUERY_METADATA: "UPDATE_WORKFILE_QUERY_METADATA",

    SET_BASIC_QUERY_FROM_FILTERS_FLAG: "SET_BASIC_QUERY_FROM_FILTERS_FLAG",
    UNSET_BASIC_QUERY_FROM_FILTERS_FLAG: "UNSET_BASIC_QUERY_FROM_FILTERS_FLAG",
    SET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG: "SET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG",
    UNSET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG: "UNSET_WORKFILE_BASIC_QUERY_FROM_FILTERS_FLAG",

    SET_COMBINE_QUERY_FLAG: "SET_COMBINE_QUERY_FLAG",
    UNSET_COMBINE_QUERY_FLAG: "UNSET_COMBINE_QUERY_FLAG",
    SET_WORKFILE_COMBINE_QUERY_FLAG: "SET_WORKFILE_COMBINE_QUERY_FLAG",
    UNSET_WORKFILE_COMBINE_QUERY_FLAG: "UNSET_WORKFILE_COMBINE_QUERY_FLAG",

    UPDATE_GROUP_BY: "UPDATE_GROUP_BY",
    UPDATE_GROUP_FIELD_OPTIONS: "UPDATE_GROUP_FIELD_OPTIONS",
    UPDATE_WORKFILE_GROUP_BY: "UPDATE_WORKFILE_GROUP_BY",

    UPDATE_FILTERS: "UPDATE_FILTERS",
    UPDATE_WORKFILE_FILTERS: "UPDATE_WORKFILE_FILTERS",
    UPDATE_FILTERS_DATA_STATUS: "UPDATE_FILTERS_DATA_STATUS",
    UPDATE_REF_PATENT_DETAILS: "UPDATE_REF_PATENT_DETAILS",
    UPDATE_SIDEBYSIDE:"UPDATE_SIDEBYSIDE",
    UPDATE_WORKFILE_FILTERS_DATA_STATUS: "UPDATE_WORKFILE_FILTERS_DATA_STATUS",
    UPDATE_CUSTOMIZE_PATENT_DATA_STATUS: "UPDATE_CUSTOMIZE_PATENT_DATA_STATUS",
    UPDATE_FILTERS_CPC_COUNT: "UPDATE_FILTERS_CPC_COUNT",
    UPDATE_FILTERS_CORP_COUNT: "UPDATE_FILTERS_CORP_COUNT",

    SET_APPLY_FILTERS_FLAG: "SET_APPLY_FILTERS_FLAG",
    UNSET_APPLY_FILTERS_FLAG: "UNSET_APPLY_FILTERS_FLAG",
    SET_WORKFILE_APPLY_FILTERS_FLAG: "SET_WORKFILE_APPLY_FILTERS_FLAG",
    UNSET_WORKFILE_APPLY_FILTERS_FLAG: "UNSET_WORKFILE_APPLY_FILTERS_FLAG",
    UPDATE_APPLIED_FILTERS: "UPDATE_APPLIED_FILTERS",
    UPDATE_WORKFILE_APPLIED_FILTERS: "UPDATE_WORKFILE_APPLIED_FILTERS",

    UPDATE_URL_FILTERS: "UPDATE_URL_FILTERS",

    UPDATE_FILTERS_FACETS_LIMIT: "UPDATE_FILTERS_FACETS_LIMIT",
    UPDATE_WORKFILE_FILTERS_FACETS_LIMIT: "UPDATE_WORKFILE_FILTERS_FACETS_LIMIT",
    
    UPDATE_PATENTS_DATA: "UPDATE_PATENTS_DATA",
    UPDATE_WORKFILE_PATENTS_DATA: "UPDATE_WORKFILE_PATENTS_DATA",
    UPDATE_STARTUP_DATA: "UPDATE_STARTUP_DATA",
    UPDATE_WORKFILE_STARTUP_DATA: "UPDATE_WORKFILE_STARTUP_DATA",
    UPDATE_PATENTS_SELECTED_COUNT: "UPDATE_PATENTS_SELECTED_COUNT",
    UPDATE_WORKFILE_PATENTS_SELECTED_COUNT: "UPDATE_WORKFILE_PATENTS_SELECTED_COUNT",
    UPDATE_PATENTS_PAGE_NUMBER: "UPDATE_PATENTS_PAGE_NUMBER",
    UPDATE_WORKFILE_PATENTS_PAGE_NUMBER: "UPDATE_WORKFILE_PATENTS_PAGE_NUMBER",
    UPDATE_WORKFILE_WORKFILE_ID: "UPDATE_WORKFILE_WORKFILE_ID",
    UPDATE_WORKFILE_WORKFILE_DETAILS:"UPDATE_WORKFILE_WORKFILE_DETAILS",
    UPDATE_WORKFILE_WORKFILE_RATING_METADATA: "UPDATE_WORKFILE_WORKFILE_RATING_METADATA",
    UPDATE_PATENTS_SORT_OPTION: "UPDATE_PATENTS_SORT_OPTION",
    UPDATE_WORKFILE_PATENTS_SORT_OPTION: "UPDATE_WORKFILE_PATENTS_SORT_OPTION",
    UPDATE_PATENT_DETAIL_INDEX: "UPDATE_PATENT_DETAIL_INDEX",
    UPDATE_WORKFILE_PATENT_DETAIL_INDEX: "UPDATE_WORKFILE_PATENT_DETAIL_INDEX",
    UPDATE_DOCUMENT_URL_BEFORE_DETAILS: "UPDATE_DOCUMENT_URL_BEFORE_DETAILS",
    UPDATE_SYNONYMS_DATA: "UPDATE_SYNONYMS_DATA",
    UPDATE_WORKFILE_SYNONYMS_DATA: "UPDATE_WORKFILE_SYNONYMS_DATA",
    UPDATE_SYNONYMS_APPLIED_DATA: "UPDATE_SYNONYMS_APPLIED_DATA",
    UPDATE_WORKFILE_SYNONYMS_APPLIED_DATA: "UPDATE_WORKFILE_SYNONYMS_APPLIED_DATA",
    UPDATE_SEMANTICS_DATA: "UPDATE_SEMANTICS_DATA",
    UPDATE_WORKFILE_SEMANTICS_DATA: "UPDATE_WORKFILE_SEMANTICS_DATA",

    SET_SELECT_ALL_PAGES: "SET_SELECT_ALL_PAGES",
    UNSET_SELECT_ALL_PAGES: "UNSET_SELECT_ALL_PAGES",
    SET_WORKFILE_SELECT_ALL_PAGES: "SET_WORKFILE_SELECT_ALL_PAGES",
    UNSET_WORKFILE_SELECT_ALL_PAGES: "UNSET_WORKFILE_SELECT_ALL_PAGES",
    SET_SELECT_ALL_CURRENT_PAGE: "SET_SELECT_ALL_CURRENT_PAGE",
    UNSET_SELECT_ALL_CURRENT_PAGE: "UNSET_SELECT_ALL_CURRENT_PAGE",
    SET_WORKFILE_SELECT_ALL_CURRENT_PAGE: "SET_WORKFILE_SELECT_ALL_CURRENT_PAGE",
    UNSET_WORKFILE_SELECT_ALL_CURRENT_PAGE: "UNSET_WORKFILE_SELECT_ALL_CURRENT_PAGE",
    UPDATE_SELECTED_PAGES: "UPDATE_SELECTED_PAGES",
    UPDATE_WORKFILE_SELECTED_PAGES: "UPDATE_WORKFILE_SELECTED_PAGES",
    
    CURSOR_DETAILS: "CURSOR_DETAILS",
    WORKFILE_CURSOR_DETAILS: "WORKFILE_CURSOR_DETAILS",
    ADD_ERROR: "ADD_ERROR",
    CLEAR_ERROR: "CLEAR_ERROR",

    SET_ERROR_NOTIFICATION: "SET_ERROR_NOTIFICATION",
    UNSET_ERROR_NOTIFICATION: "UNSET_ERROR_NOTIFICATION",

    ADD_EXPORT_FILE : "ADD_EXPORT_FILE",
    REMOVE_EXPORT_FILE: "REMOVE_EXPORT_FILE",

    ADD_DASHBOARD_FILE : "ADD_DASHBOARD_FILE",
    REMOVE_DASHBOARD_FILE: "REMOVE_DASHBOARD_FILE",

    SET_SEARCH_FLAG_FROM_HISTORY: "SET_SEARCH_FLAG_FROM_HISTORY",
    SET_FILTERS_FROM_HISTORY: "SET_FILTERS_FROM_HISTORY",
    SET_TAXONOMY_FROM_HISTORY: "SET_TAXONOMY_FROM_HISTORY",

    SET_INITIAL_LOAD: "SET_INITIAL_LOAD",
    SET_WORKFILE_INITIAL_LOAD: "SET_WORKFILE_INITIAL_LOAD",

    UPDATE_CREATE_ALERT_DATA: "UPDATE_CREATE_ALERT_DATA",

    UPDATE_SHOW_FULLCHARTS_FLAG: "UPDATE_SHOW_FULLCHARTS_FLAG",
    UPDATE_PATENT_POSITION_REF: "UPDATE_PATENT_POSITION_REF",
    UPDATE_WORKFILE_PATENT_POSITION_REF: "UPDATE_WORKFILE_PATENT_POSITION_REF",
    
    UPDATE_HIGHLIGHTED_KEYWORDS: "UPDATE_HIGHLIGHTED_KEYWORDS",
    UPDATE_WORKFILE_HIGHLIGHTED_KEYWORDS: "UPDATE_WORKFILE_HIGHLIGHTED_KEYWORDS",

    UPDATE_HIGHLIGHTED_WORDFORMS: "UPDATE_HIGHLIGHTED_WORDFORMS",

    UPDATE_HIGHLIGHT_ON_OFF_FLAG: "UPDATE_HIGHLIGHT_ON_OFF_FLAG",

    UPDATE_VIEWED_PATENTS: "UPDATE_VIEWED_PATENTS",
    UPDATE_SAVE_TO_WORKFILE_API:"UPDATE_SAVE_TO_WORKFILE_API",
    UPDATE_WORDFORMS_DATA:"UPDATE_WORDFORMS_DATA",
    UPDATE_PATENTS_STATS_DATA:"UPDATE_PATENTS_STATS_DATA",
    UPDATE_WORKFILE_PATENTS_STATS_DATA:"UPDATE_WORKFILE_PATENTS_STATS_DATA",
    UPDATE_HIDDEN_CITATIONS_DATA:"UPDATE_HIDDEN_CITATIONS_DATA",
    UPDATE_HIDDEN_CITN_CUR_LEVEL:"UPDATE_HIDDEN_CITN_CUR_LEVEL",
    UPDATE_HIDDEN_CITN_EACH_LEVEL:"UPDATE_HIDDEN_CITN_EACH_LEVEL",
    UPDATE_HIDDEN_CITN_PAGE_NUMBER:"UPDATE_HIDDEN_CITN_PAGE_NUMBER",
    UPDATE_HIDDEN_CITN_PATENTS_SELECTED_COUNT:"UPDATE_HIDDEN_CITN_PATENTS_SELECTED_COUNT",
    SET_HIDDEN_CITN_SELECT_ALL_PAGES:"SET_HIDDEN_CITN_SELECT_ALL_PAGES",
    UNSET_HIDDEN_CITN_SELECT_ALL_PAGES:"UNSET_HIDDEN_CITN_SELECT_ALL_PAGES",
    SET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE:"SET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE",
    UNSET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE:"UNSET_HIDDEN_CITN_SELECT_ALL_CURRENT_PAGE",
    UPDATE_HIDDEN_CITN_SELECTED_PAGES:"UPDATE_HIDDEN_CITN_SELECTED_PAGES",
    UPDATE_MISSING_PATENTS_DATA:"UPDATE_MISSING_PATENTS_DATA",
    SET_APPLY_MISSING_PATENTS_FLAG:"SET_APPLY_MISSING_PATENTS_FLAG",
    UNSET_APPLY_MISSING_PATENTS_FLAG:"UNSET_APPLY_MISSING_PATENTS_FLAG",

    UPDATE_ORDERING_DATA: "UPDATE_ORDERING_DATA",
    UPDATE_GROUP_SORT: "UPDATE_GROUP_SORT",
    UPDATE_ADD_USER_SYNONYMS_FLAG: "UPDATE_ADD_USER_SYNONYMS_FLAG",
    UPDATE_RUN_CLONED_QUERY_FLAG:"UPDATE_RUN_CLONED_QUERY_FLAG",
    UPDATE_DEV_CONTROLS: "UPDATE_DEV_CONTROLS",
    UPDATE_SESSION_GROUP_DATA: "UPDATE_SESSION_GROUP_DATA",
    UPDATE_FETCH_FILTER_KEYS: "UPDATE_FETCH_FILTER_KEYS",
    UPDATE_USER_GROUPS: "UPDATE_USER_GROUPS",
    UPDATE_DOCUMENT_MAX_SCORE: "UPDATE_DOCUMENT_MAX_SCORE",
    UPDATE_LANGUAGE:"UPDATE_LANGUAGE",
    UPDATE_PREVIEW_TYPE_CONFIG_INFO: "UPDATE_PREVIEW_TYPE_CONFIG_INFO",
    UPDATE_DOCUMENTSPATH: "UPDATE_DOCUMENTSPATH",
    UPDATE_EXPAND_COUNT_HISTORY:"UPDATE_EXPAND_COUNT_HISTORY",
    UPDATE_YEAR_TABLE_FILTER_FLAG:"UPDATE_YEAR_TABLE_FILTER_FLAG",
    UPDATE_TAXONOMY_PREVIOUS_ACTIVE_TAB:"UPDATE_TAXONOMY_PREVIOUS_ACTIVE_TAB",
    UPDATE_DATASOURCE_DATA:"UPDATE_DATASOURCE_DATA",
    UPDATE_DOCUMENT_TYPE: "UPDATE_DOCUMENT_TYPE",
    UPDATE_DETAILS_VIEW_TYPE: "UPDATE_DETAILS_VIEW_TYPE",

    UPDATE_CONNECTOR_SCREEN: "UPDATE_CONNECTOR_SCREEN",
    UPDATE_CONNECTOR_CONFIGPARAMS: "UPDATE_CONNECTOR_CONFIGPARAMS",
    UPDATE_CONNECTOR_CONFIG: "UPDATE_CONNECTOR_CONFIG",
    UPDATE_CONNECTOR_INFO: "UPDATE_CONNECTOR_INFO",
    UPDATE_ACTIVE_TAB:"UPDATE_ACTIVE_TAB",
    UPDATE_DOCUMENT_TAGGING_OPTIONS: "UPDATE_DOCUMENT_TAGGING_OPTIONS",
    UPDATE_PROJECT_SETTINGS_FLAG: "UPDATE_PROJECT_SETTINGS_FLAG",
    UPDATE_IMPORT_PATENTS_DATA: "UPDATE_IMPORT_PATENTS_DATA",
    UPDATE_APPLIED_SIMILARITY_FILTER:"UPDATE_APPLIED_SIMILARITY_FILTER",
    UPDATE_ALERTS_STATUS_PATH:"UPDATE_ALERTS_STATUS_PATH",
    UPDATE_SMART_IMPORT_SHEETS_DATA: "UPDATE_SMART_IMPORT_SHEETS_DATA",
    UPDATE_PATENTS_PRODUCTS_MAPPING_DATA: "UPDATE_PATENTS_PRODUCTS_MAPPING_DATA",
    SET_CLAIM_CHARTS_ENABLE: "SET_CLAIM_CHARTS_ENABLE",
    UNSET_CLAIM_CHARTS_ENABLE: "UNSET_CLAIM_CHARTS_ENABLE",
    SET_PRODUCTS_ENABLE: "SET_PRODUCTS_ENABLE",
    UNSET_PRODUCTS_ENABLE: "UNSET_PRODUCTS_ENABLE",
    SET_LEVEL2_ANALYSIS_ENABLE: "SET_LEVEL2_ANALYSIS_ENABLE",
    UNSET_LEVEL2_ANALYSIS_ENABLE: "UNSET_LEVEL2_ANALYSIS_ENABLE",
    UPDATE_LEVEL2_SELECTED_PATENTS: "UPDATE_LEVEL2_SELECTED_PATENTS",
    UPDATE_CLAIM_CHART_GENERATION_LOADING: "UPDATE_CLAIM_CHART_GENERATION_LOADING",
    UPDATE_PROJECT_NOTIFICATION_COUNT: "UPDATE_PROJECT_NOTIFICATION_COUNT",
    UPDATE_CURRENT_SEARCH: "UPDATE_CURRENT_SEARCH"
}

export default constants;