import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Routes, Route } from 'react-router-dom';
import './index.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css'; // Correct path for FontAwesome styles
import 'react-select/dist/react-select.css';

import PcsRoutes from './components/routes/pcs';
import PriorArtRoutes from './components/routes/priorArt';
import WorkfileRoutes from './components/routes/workfiles';
import ClaimAnalysisRoutes from './components/routes/claimAnalysis';
import Tdocs from './components/routes/tdocs';
import ClaimCorrelation from './components/routes/claimCorrelation';

import { updateConfig, updateGroupBy, updateCutomizePatentDataStatus, updateSessionGroupData, updateLanguage } from './store/actions';
import { updateWorkfileGroupBy } from './store/actions';
import * as Storage from './components/services/localstorage';

class Home extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            configure: {},
            readConfigureDone: false,
            restoredSessionData: false
        };

        this.getRoute = this.getRoute.bind(this);
    }

    componentDidMount() {
        console.log("Routes container");

        axios.get('configure.json')
            .then((res) => {
                const configure = res.data;
                this.props.updatingConfig(configure);
                const groupBy = configure && configure.defaultReduce ? 'ucid' : configure.defaultReduce;
                this.props.updatingGroupBy(groupBy);
                this.props.updatingWorkfileGroupBy(groupBy);

                if (configure.defaultCustomFields) {
                    const customFields = {};
                    configure.defaultCustomFields.forEach((eachField) => {
                        customFields[eachField] = true;
                    });
                    this.props.updatingCutomizePatentDataStatus(customFields);
                }

                this.setState({ configure, readConfigureDone: true });
                this.restoringSessionData(configure);
            })
            .catch((error) => {
                console.error('An error occurred configuration:', error);
            });
    }

    restoringSessionData = (config) => {
        const key = config.sessionKey;
        let sessionData = Storage.get(key);
        sessionData = sessionData ? JSON.parse(sessionData) : {};

        const session_group_data = sessionData["userGroups"] ? sessionData["userGroups"] : {};        
        this.props.updatingSessionGroupData(session_group_data);

        if (sessionData["language"] && sessionData["language"] !== '')
            this.props.updatingLanguage(sessionData["language"]);
        
        this.setState({ restoredSessionData: true });
    };



    getRoute(route){
        
        if(route === "pcs")
            return <PcsRoutes config={this.state.configure}/>;
        else if(route === "priorart")
            return <PriorArtRoutes config={this.state.configure}/>;
        else if(route === "workfile")
            return <WorkfileRoutes config={this.state.configure}/>;
        else if(route === "claimAnalysis")
            return <ClaimAnalysisRoutes config={this.state.configure}/>;
        else if(route === "claimCorrelation")
            return <ClaimCorrelation config={this.state.configure}/>;
        else if(route === "tdocs")
            return <Tdocs config={this.state.configure}/>;
        else 
            return <PcsRoutes config={this.state.configure}/>;
            
    }

    render() {
        const { readConfigureDone, configure, restoredSessionData } = this.state;

        // console.log("home component: ", this.props);

        return ( 
            (this.state.readConfigureDone && this.state.configure.source && this.state.restoredSessionData) ?
               this.getRoute(this.state.configure.source) : 
               <span></span>  //Dummy return instead of empty in render
            );
    }
}

const mapStateToProps = (state) => ({
    config: state.config
});
  
const mapDispatchToProps = (dispatch) => ({
    updatingConfig(data) {
        dispatch(updateConfig(data));
    },
    updatingGroupBy(data) {
        dispatch(updateGroupBy(data));
    },
    updatingWorkfileGroupBy(data) {
        dispatch(updateWorkfileGroupBy(data));
    },
    updatingCutomizePatentDataStatus(data) {
        dispatch(updateCutomizePatentDataStatus(data));
    },
    updatingSessionGroupData(data) {
        dispatch(updateSessionGroupData(data));
    },
    updatingLanguage(data) {
        dispatch(updateLanguage(data));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
